/**
 * --------------------------------------------------------------------------
 * Public Util Api
 * --------------------------------------------------------------------------
 */

const Util = {

  /**
   * Retrouve le parent d'un élément selon une classe fournie (équivalent à closest)
   * @param {HTMLElement} element - L'élément enfant
   * @param {string} className - La classe parente
   *
   * @return {HTMLElement} - L'élément parent
   */
  findParentByClassName(element, className) {
    while ((element = element.parentElement) && !element.classList.contains(className)) { }
    return element;
  },

  /**
   * Retourne la position droite depuis le viewport d'un élément
   * @param {HTMLElement} element - L'élément ciblé
   *
   * @return {number} - position droite
   */
  getRightPosFromElement(element) {
    const { x, width } = element.getBoundingClientRect();
    return x + width;
  }
};

export default Util;
