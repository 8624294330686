import Ob1Component from "../ob1-component";

const spinnerDeterminedTagNames = {
  DIV: "div"
};

const spinerDeterminedClasses = {
  SPINNER: "ob1-spinner-determined",
  LOADER1: "ob1-spinner-determined-loader1",
  LOADER2: "ob1-spinner-determined-loader2"
};

const spinerDeterminedAttributes = {
  TABINDEX: "tabindex",
  TABINDEX_NEGATIVE: "-1",
  ARIA_LABEL: "aria-label",
  ARIA_LABEL_SPINNER: "Chargement en cours"
};

class SpinnerDetermined extends Ob1Component {

  constructor(container, parameters) {
    super(container, "SpinnerDetermined", parameters);
  }

  /**
   * Initialisation du composant
   * @param {HTMLElement} container - L'élément représentant le spinner
   * @param {object} parameters les paramètres d'instanciation de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    // On postionne le focus sur l'élément pour lancer la vocalisation
    this.container.setAttribute(spinerDeterminedAttributes.ARIA_LABEL,
      spinerDeterminedAttributes.ARIA_LABEL_SPINNER);
    this.container.setAttribute(spinerDeterminedAttributes.TABINDEX,
      spinerDeterminedAttributes.TABINDEX_NEGATIVE);
    this.container.focus();

    // On crée les 2 loaders nécessaires à l'animation
    this.loader1 = this.createLoader(spinerDeterminedClasses.LOADER1);
    this.loader2 = this.createLoader(spinerDeterminedClasses.LOADER2);
    this.container.appendChild(this.loader1);
    this.container.appendChild(this.loader2);

    // On applique une temporisation avant le démarrage du scale (disparition du cercle)
    this.container.style.animationDelay = this.container.dataset.spinnerdeterminedTimer + "s";

    // On applique une durée à l'animation du premier loader
    this.loader1.style.animationDuration = this.container.dataset.spinnerdeterminedTimer / 2 + "s";

    // On applique une durée et une temporisation à l'animation du deuxième loader
    this.loader2.style.animationDelay = this.container.dataset.spinnerdeterminedTimer / 2 + "s";
    this.loader2.style.animationDuration = this.container.dataset.spinnerdeterminedTimer / 2 + "s";
  }

  /**
   * Masque le spinner
   */
  hide() {
    this.container.classList.add("d-none");
  }

  /**
   * Affiche le spinner
   */
  show() {
    this.container.classList.remove("d-none");
  }

  /**
   * Lance l'animation du spinner
   */
  reload() {
    this.container.classList.remove(spinerDeterminedClasses.SPINNER);
    void this.container.offsetWidth;
    this.container.classList.add(spinerDeterminedClasses.SPINNER);
  }

  /**
   * Création d'un élément loader
   * @param {String} className - Le nom de la classe appliquée sur l'élément
   */
  createLoader(className) {
    const loader = document.createElement(spinnerDeterminedTagNames.DIV);
    loader.classList.add(className);

    return loader;
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.SpinnerDetermined = SpinnerDetermined;

export default SpinnerDetermined;
