import * as OB1Common from "./ob1-common";

class DatePicker {

  /**
   * Initialisation du Date Picker
   */
  init(container, dates, mobileModeDisabled) {
    this.container = container;

    // Au chargement, test d'affichage de la version du Date Picker, mobile ou desktop
    this.changeDisplayMode(dates, mobileModeDisabled);

    this._addEvents(dates, mobileModeDisabled);
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents(dates, mobileModeDisabled) {

    // gestion de l'évenement resize du navigateur pour choix affichage du Date Picker mobile ou desktop
    this._onResize = () => {
      this.changeDisplayMode(dates, mobileModeDisabled);
    };
    window.addEventListener("resize", this._onResize);

    // Gestion des écouteurs
    this._onClick = (event) => {
      if (event.target.classList.contains("changeDatePickerSize")) {
        this.changeDatePickerSize(mobileModeDisabled);
      }

      // On monte le date picker de 2px pour cacher la bordure de l'input et éviter le double liseret
      const topVal = parseInt(document.getElementById("ui-datepicker-div").style.top, 10);
      document.getElementById("ui-datepicker-div").style.top = (topVal - 2) + "px";
    };
    this.container.addEventListener("click", this._onClick);
  }

  // affichage ou masquage du datepicker Desktop en fonction de la résolution
  changeDisplayMode(dates, mobileModeDisabled) {
    let selectedDate = this.container.querySelector(".inputDatePicker").value;
    let windowWidth = document.documentElement.clientWidth;

    if (windowWidth >= OB1Common.breakpoints.MEDIUM || mobileModeDisabled) {

      // Changement du type de date picker en desktop
      this.container.querySelector(".inputDatePicker").type = "text";

      if (!this.container.querySelector(".icon-calendar-day")) {
        const iconEl = document.createElement("span");
        iconEl.classList.add("icon", "icon-calendar-day");
        iconEl.setAttribute("aria-hidden", "true");
        this.container.querySelector(".labelDatePicker").parentNode
          .insertBefore(iconEl, this.container.querySelector(".labelDatePicker").nextSibling);
      }

      // Paramétrage du datepicker Desktop
      $(this.container.querySelector(".inputDatePicker")).datepicker({
        closeText: "Fermer",
        prevText: "Précédent",
        nextText: "Suivant",
        currentText: "Aujourd'hui",
        monthNames: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
        monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
          "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
        dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
        dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
        dayNamesMin: [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        weekHeader: "Sem.",
        dateFormat: "dd/mm/yy",

        // fonction pour modifier la largeur du Date Picker Desktop à X mois
        onChangeMonthYear: function () {
          let datepickerDiv = document.getElementById("ui-datepicker-div");
          datepickerDiv.style.setProperty("font-size", "1.2em");
        },

        // fonction pour mettre à jour l'affichage du champs une fois la date sélectionnée
        onSelect: function () {
          this.classList.remove("form-control-empty");
        },

        beforeShowDay: this._beforeShowDay(dates),

        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
        numberOfMonths: windowWidth < OB1Common.breakpoints.MEDIUM && mobileModeDisabled ? 1 : 2,
        minDate: new Date(),
        maxDate: "+1y",
        showButtonPanel: false
      });

      // si changement de résolution, on récupère date sélectionnée dans Date Picker Mobile pour alimenter dans Date Picker Desktop
      let smallSelectedDate = selectedDate.split("-").reverse().join("/");
      $(this.container.querySelector(".inputDatePicker")).datepicker("setDate", smallSelectedDate);
    } else {

      // Changement du type de date picker en mobile
      this.container.querySelector(".inputDatePicker").type = "date";
      if (this.container.querySelector(".icon-calendar-day")) {
        this.container.querySelector(".icon-calendar-day").remove();
      }

      // fonction pour mettre à jour la date du Date Picker Mobile à partir de la sélection du Date Picker Desktop
      let bigSelectedDate = selectedDate.split("/").reverse().join("-");
      this.container.querySelector(".inputDatePicker").value = bigSelectedDate;
    }
  }

  // fonction pour modifier la largeur du Date Picker Desktop à X mois
  changeDatePickerSize(mobileModeDisabled) {
    let datepickerDiv = document.getElementById("ui-datepicker-div");
    datepickerDiv.style.setProperty("font-size", "1.2em");

    // Masquage de la DIV date Picker en mobile
    let windowWidth = document.documentElement.clientWidth;
    if (windowWidth < OB1Common.breakpoints.MEDIUM && !mobileModeDisabled) {
      document.getElementById("ui-datepicker-div").style.display = "none";
    }
  }

  /**
   * Détermine une liste de dates prédéfinies
   *
   * @param {*} dates  tableau de dates prédéfinies au format "yy-mm-dd"
   */
  changeSelectableDates(dates) {
    $(this.container.querySelector(".inputDatePicker"))
      .datepicker("option", "beforeShowDay", this._beforeShowDay(dates));
  }

  _beforeShowDay(dates) {
    return function(date) {
      const dateWhitoutHours = jQuery.datepicker.formatDate("yy-mm-dd", date);
      if (dates.length > 0) {
        return [ dates.indexOf(dateWhitoutHours) !== -1 ];
      } else {
        return dateWhitoutHours;
      }
    };
  }

  /**
   * Constructeur du Date Picker
   */
  constructor(container, dates = [], mobileModeDisabled = false) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        DatePicker.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container, dates, mobileModeDisabled);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return DatePicker.refs[ container.dataset.ref ];
      }
    }
  }

  /**
   * Décharge le composant
   */
  dispose() {
    window.removeEventListener("resize", this._onResize);
    this.container.removeEventListener("click", this._onClick);
  }
}

DatePicker.refs = {};

document.addEventListener("DOMContentLoaded", () => {
  let datepickers = document.querySelectorAll(".date-picker");
  [].forEach.call(datepickers, (datepicker) => {
    new DatePicker(datepicker);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.DatePicker = DatePicker;

export default DatePicker;
