const carrouselClasses = {
  carrousel: "ob1-carrousel",
  pagination: "swiper-pagination",
  next: "swiper-button-next",
  prev: "swiper-button-prev",
  controls: "ob1-carrousel-controls",
  play: "ob1-carrousel-controls-play",
  page_indicator_item: "ob1-page-indicator-item",
  page_indicator_active: "ob1-page-indicator-active",
  slide: "swiper-slide"
};

const carrouselAttributes = {
  slide_index: "data-swiper-slide-index"
};

const carrouselTexts = {
  pause: "Mettre en pause le carrousel",
  play: "Reprendre la lecture du carrousel"
};

class Carrousel {
  constructor (carrouselEl, autoplay = true) {
    if (typeof carrouselEl.swiper !== "object") {
      this.config = {
        a11y: {
          enabled: false
        },
        loop: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false
        },
        pagination: {
          el: "." + carrouselClasses.pagination,
          clickable: true,
          type: "bullets",
          bulletClass: carrouselClasses.page_indicator_item,
          bulletActiveClass: carrouselClasses.page_indicator_active,
          renderBullet: function(index, className) {
            return "<li class=\"" + className + "\">" +
              "<button class=\"ob1-page-indicator-button\" type=\"button\" tabindex=\"-1\"></button>" +
              "</li>";
          }
        },
        navigation: {
          nextEl: "." + carrouselClasses.next,
          prevEl: "." + carrouselClasses.prev
        },
        observer: true,
        observeParents: true,
        on: {
          init: function() {
            Array.from(document.querySelectorAll(".swiper-slide-duplicate")).forEach((duplicateSlide) => {
              duplicateSlide.setAttribute("tabindex", -1);
              duplicateSlide.setAttribute("aria-hidden", true);
            });
          }
        }
      };

      if (autoplay) {
        this.config.autoplay = {
          delay: 6000,
          disableOnInteraction: false
        };
      }

      this.carrouselEl = carrouselEl;

      new Swiper(carrouselEl, this.config);

      if (autoplay) {
        this.controlsListener();
      }
      this.slideToOnFocus(autoplay);
    }
  }

  /**
   * Le bouton "pause" étant un composant personnalisé, cette fonction permet d'ajouter les écouteurs nécessaires au
   * contrôle du carrousel
   */
  controlsListener() {
    this._onClick = (control) => {
      if (this.carrouselEl.swiper.autoplay.running) {
        this.carrouselEl.swiper.autoplay.stop();
        control.setAttribute("title", carrouselTexts.play);
        control.setAttribute("aria-label", carrouselTexts.play);
        control.classList.add(carrouselClasses.play);
        control.children[ 0 ].classList.remove("icon-Pause");
        control.children[ 0 ].classList.add("icon-Play");
        control.children[ 1 ].textContent = "Lecture";
      } else {
        this.carrouselEl.swiper.autoplay.start();
        control.setAttribute("title", carrouselTexts.pause);
        control.setAttribute("aria-label", carrouselTexts.pause);
        control.classList.remove(carrouselClasses.play);
        control.children[ 0 ].classList.remove("icon-Play");
        control.children[ 0 ].classList.add("icon-Pause");
        control.children[ 1 ].textContent = "Pause";
      }
    };

    const control = this.carrouselEl.querySelector("." + carrouselClasses.controls);
    this._onClickControl = this._onClick.bind(this, control);
    control.addEventListener("click", this._onClickControl);
  }

  /**
   * Gestion du focus des slides, chaque slide est focusable, on déplace le slide active pour qu'il soit visible puis
   * on stoppe l'autoplay. Quand on perds le focus, on relance l'autoplay.
   */
  slideToOnFocus(autoplay) {
    this._onFocus = (slide) => {
      slide.closest("." + carrouselClasses.carrousel).scrollLeft = 0;
      let slideIndex = parseInt(slide.getAttribute(carrouselAttributes.slide_index));
      this.carrouselEl.swiper.autoplay.stop();
      this.carrouselEl.swiper.slideToLoop(slideIndex);
    };

    this._onBlur = () => {
      this.carrouselEl.swiper.autoplay.start();
    };

    this._onFocuses = [];
    Array.from(this.carrouselEl.querySelectorAll("." + carrouselClasses.slide)).forEach((slide, index) => {
      this._onFocuses[ index ] = this._onFocus.bind(this, slide);
      slide.addEventListener("focus", this._onFocuses[ index ]);

      if (autoplay) {
        slide.addEventListener("blur", this._onBlur);
      }
    });
  }

  /**
   * Décharge le composant
   */
     dispose () {

      // suppression de tous les event listeners qui ont été créés
      const control = this.carrouselEl.querySelector("." + carrouselClasses.controls);
      control.removeEventListener("click", this._onClickControl);

      Array.from(this.carrouselEl.querySelectorAll("." + carrouselClasses.slide)).forEach((slide, index) => {
        slide.removeEventListener("focus", this._onFocuses[ index ]);

        if (autoplay) {
          slide.removeEventListener("blur", this._onBlur);
        }
      });
    }
}

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll(".ob1-carrousel")).forEach( (carrousel) => {
    new Carrousel(carrousel);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Carrousel = Carrousel;

export default Carrousel;
