import Util from "./util";

const PAGE_MENU_LISTENERS = {
  CLICK: "click"
};

const PAGE_MENU_CLASSES = {
  ITEM_ACTIVE: "active",
  ITEM_INLINE: "ob1-nav-inline",
  DROPDOWN: "ob1-menu-page-dropdown", // on utilise pas .dropdown pour éviter l'instanciation du composant
  DROPDOWN_MENU: "dropdown-menu",
  DROPDOWN_ITEM: "dropdown-item",
  MORE_ITEMS_HIDDEN: "d-none",
  NAV_LINK: "nav-link",
  NAV_ITEM: "nav-item"
};

const ATTRIBUTES = {
  HREF: "href",
  HREF_VALUE: "#"
};

const PAGE_MENU_TEXTS = {
  MORE_ITEMS_NAME: "Plus"
};

// la liste des sélecteurs que l'on utilise dans le code du composant
const PAGE_MENU_SELECTORS = {
  CONTAINER: ".nav.o-nav-light",
  ALL_ITEMS: ".nav.o-nav-light .nav-item",
  NAV_LINK: ".nav-link",
  DROPDOWN_LINK: ".ob1-menu-page-dropdown .nav-link",
  DROPDOWN_MENU: ".dropdown-menu",
  DROPDOWN_ITEM: ".dropdown-item"
};

class PageMenu {

  /**
   * Constructeur
   * @param {HTMLElement} pageMenu - L'élément représentant le menu de navigation
   */
  constructor(pageMenu) {

    this.pageMenu = pageMenu;

    // Si le PageMenu a déjà été instancié sur ce noeud on ne réinstancie pas
    if (this.pageMenu.dataset.refPageMenu === "true") {
      return;
    }


    // On initialise l'élément "Plus" qui contiendra les items ne contenant pas sur une seule ligne
    this.moreItems = this.createMoreItemsElement();

    // Liste des items présents dans l'élément "Plus"
    this.moreItemsList = this.moreItems.querySelector(PAGE_MENU_SELECTORS.DROPDOWN_MENU);

    // Lien du dropdown - "Plus"
    this.moreItemsLink = this.pageMenu.querySelector(PAGE_MENU_SELECTORS.DROPDOWN_LINK);

    // Si aucun élément n'est sélectionné, on sélectionne par défaut le premier élément
    const items = pageMenu.querySelectorAll(PAGE_MENU_SELECTORS.ALL_ITEMS);
    let isOneSelected = false;
    for (let item of items) {
      if (item.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE)) {
        isOneSelected = true;
        break;
      }
    }
    if (!isOneSelected) {
      pageMenu.querySelector(PAGE_MENU_SELECTORS.ALL_ITEMS).classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
    }

    // On met en place des listners permettant la mise en évidence (soulignement + couleur Orange) de l'item sélectionné
    this.setActiveItemManagement();

    // Si on est sur un device n'utilisant pas de souris
    if (window.matchMedia("(hover:none)").matches) {

      // On affiche les items sur une seule ligne afin que l'utilisateur puisse swipper
      document.querySelector(PAGE_MENU_SELECTORS.CONTAINER).classList.add(PAGE_MENU_CLASSES.ITEM_INLINE);

      this.showOrHideMoreItems();

    } else {

      // On appelle la méthode de redimmensionnement du menu afin d'ajouter/retirer d'éventuels items dans l'élément "Plus"
      this.resize();

      // On crée un listener pour gérer l'ajout/retrait d'items dans l'élément "Plus" lors d'un redimensionnement de la page
      window.addEventListener("resize", () => {
        this.resize();
      });
    }

    this.pageMenu.dataset.refPageMenu = "true";
  }

  /**
   * Permet de gérer le redimensionnement du composant
   */
  resize() {
    this.showOrHideMoreItems();

    // A chaque resize on vérifie si on a au moins 1 item dans "Plus", si c'est le cas on re-vide le dropdown
    if (this.moreItemsList.childElementCount > 0) {
      this.removeItemsFromDropdown();
    }

    // Tant qu'il y a un overflow, on ajoute le dernier élément dans la dropdown
    while (this.pageMenu.offsetWidth < this.pageMenu.scrollWidth && this.pageMenu.childElementCount > 1) {
      this.moveLastPageMenuItemToDropdown();
    }

    // On vérifie que la dropdown puisse s'afficher dans la page sans overflow
    if (this.moreItemsList.childElementCount > 0 && this.pageMenu.childElementCount > 1) {

      // On affiche temporairement le dropdown pour les calculs de positionnement
      this.moreItemsList.classList.add("show");

      const parentContainer = Util.findParentByClassName(this.moreItemsList, ".container-fluid") || this.pageMenu;

      while (Util.getRightPosFromElement(this.moreItemsList) > Util.getRightPosFromElement(parentContainer) &&
        this.pageMenu.childElementCount > 1
      ) {
        this.moveLastPageMenuItemToDropdown();
      }
      this.moreItemsList.classList.remove("show");
    }
  }

  /**
   * Méthode de déplacement du dernier élément du page menu dans la dropdown
   */
  moveLastPageMenuItemToDropdown() {
    let elementToMove = this.pageMenu.children[ this.pageMenu.children.length - 2 ];

    let elementToAdd = elementToMove.querySelector(".nav-link");
    elementToAdd.classList.add(PAGE_MENU_CLASSES.DROPDOWN_ITEM);
    elementToAdd.classList.remove("nav-link");

    // Si au moins 1 item est déjà présent dans "Plus" , on insère le nouvel item au début de la liste
    if (this.moreItemsList.childElementCount > 0) {
      this.moreItemsList.insertBefore(elementToAdd,
        this.moreItemsList.querySelector(PAGE_MENU_SELECTORS.DROPDOWN_ITEM));

      // Sinon on ajoute l'item dans la liste vide
    } else {
      this.moreItemsList.appendChild(elementToAdd);
    }

    // On change les styles de l'élément déplacé et de l'item "Plus"
    if (elementToMove.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE)) {
      this.moreItems.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
      elementToAdd.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
      this.moreItemsLink.textContent = elementToAdd.textContent;
    }

    this.pageMenu.removeChild(elementToMove);
    this.showOrHideMoreItems();
  }

  /**
   * Méthode de déplacement de tous les items de la dropdown dans le page menu
   */
  removeItemsFromDropdown() {
    const numberMoreItemsListChildren = this.moreItemsList.childElementCount;
    let index = 0;
    while (index < numberMoreItemsListChildren) {
      let elementToMove = this.moreItemsList.querySelector(PAGE_MENU_SELECTORS.DROPDOWN_ITEM);

      let elementToAdd = document.createElement("li");
      elementToAdd.classList.add(PAGE_MENU_CLASSES.NAV_ITEM);
      elementToMove.classList.add(PAGE_MENU_CLASSES.NAV_LINK);
      elementToMove.classList.remove(PAGE_MENU_CLASSES.DROPDOWN_ITEM);
      elementToAdd.appendChild(elementToMove);

      if (elementToMove.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE)) {
        this.moreItems.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        elementToAdd.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        this.moreItemsLink.textContent = PAGE_MENU_TEXTS.MORE_ITEMS_NAME;
        elementToMove.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);
      }

      this.pageMenu.insertBefore(elementToAdd, this.pageMenu.children[ this.pageMenu.childElementCount - 1 ]);
      index++;
    }
  }

  /**
   * Crée l'élément appelé "Plus" et contenant les items du menu ne pouvant pas être affichés sur une seule ligne
   * @return {HTMLElement} - L'élément "Plus"
   */
  createMoreItemsElement() {
    let moreItemsElement = document.createElement("li");
    moreItemsElement.classList.add(PAGE_MENU_CLASSES.DROPDOWN);
    moreItemsElement.classList.add(PAGE_MENU_CLASSES.NAV_ITEM);

    let linkMoreItemsElement = document.createElement("a");
    linkMoreItemsElement.setAttribute(ATTRIBUTES.HREF, ATTRIBUTES.HREF_VALUE);

    linkMoreItemsElement.classList.add(PAGE_MENU_CLASSES.NAV_LINK);
    linkMoreItemsElement.classList.add("o-link-arrow");
    linkMoreItemsElement.classList.add("down");
    linkMoreItemsElement.innerText = PAGE_MENU_TEXTS.MORE_ITEMS_NAME;

    let moreItemsList = document.createElement("div");
    moreItemsList.classList.add(PAGE_MENU_CLASSES.DROPDOWN_MENU);

    moreItemsElement.appendChild(linkMoreItemsElement);
    moreItemsElement.appendChild(moreItemsList);

    this.pageMenu.appendChild(moreItemsElement);

    return moreItemsElement;
  }

  /**
   * Affiche/masque l'élément "Plus" en fonction du nombre d'items présents dans cet élément
   */
  showOrHideMoreItems() {
    if (this.moreItemsList.childElementCount === 0) {
      this.moreItems.classList.add(PAGE_MENU_CLASSES.MORE_ITEMS_HIDDEN);
    } else if (this.moreItemsList.childElementCount > 0) {
      this.moreItems.classList.remove(PAGE_MENU_CLASSES.MORE_ITEMS_HIDDEN);
    }
  }

  /**
   * Création de listeners permettant la mise en valeur d'un item lors d'un clic sur ce dernier
   */
  setActiveItemManagement() {

    // Listener appelé au clic sur un élément non compris dans le dropdown
    this._onElClick = (event) => {
      this.removeAllActiveClasses();

      if (event.target.classList.contains("dropdown-item")) {
        event.target.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        this.moreItems.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);

        // On met à jour le label de l'élément "Plus"
        this.moreItemsLink.innerText = event.target.innerText;
      } else {
        event.target.parentElement.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        this.moreItemsLink.innerText = PAGE_MENU_TEXTS.MORE_ITEMS_NAME;
      }

      event.preventDefault();
    };

    this._callbackForEachLink((link) => link.addEventListener(PAGE_MENU_LISTENERS.CLICK, this._onElClick));

    // On ajoute un listener sur l'élément "Plus"
    this._onMoreClick = (event) => event.preventDefault();
    this.moreItemsLink.addEventListener(PAGE_MENU_LISTENERS.CLICK, this._onMoreClick);
  }

  /**
   * Supprime la classe "active" de tous les éléments, dropdown ou non
   */
  removeAllActiveClasses() {
    Array.prototype.slice.call(this.pageMenu.children, 0)
      .concat(Array.prototype.slice.call(this.moreItemsList.children, 0))
      .forEach((child) => child.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE));
  }

  /**
   * Décharge le composant
   */
   dispose () {
    window.removeEventListener("resize", this.resize);
    this._callbackForEachLink((elem) => elem.removeEventListener(PAGE_MENU_LISTENERS.CLICK, this._onElClick));
    this.moreItemsLink.removeEventListener(PAGE_MENU_LISTENERS.CLICK, this._onMoreClick);
  }

  /**
   * Appelle la callback pour chaque lien du Page Menu (sauf lien Plus) avec le HTMLElement du lien en paramètre
   */
  _callbackForEachLink(callbackfn) {
    Array.prototype.slice.call(this.pageMenu.children, 0)
      .filter((listItem) => !listItem.classList.contains("dropdown"))
      .map((element) => element.querySelector(".nav-link"))
      .filter((item) => item) // querySelector pourrait retourner null si le dom n'est pas correct
      .concat(Array.prototype.slice.call(this.moreItemsList.children, 0))
      .forEach(callbackfn);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let pageMenus = document.querySelectorAll(PAGE_MENU_SELECTORS.CONTAINER);
  [].forEach.call(pageMenus, (pageMenu) => {
    new PageMenu(pageMenu);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.PageMenu = PageMenu;

export default PageMenu;
