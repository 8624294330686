import Ob1Component from "./ob1-component";

class Snackbar extends Ob1Component {

  constructor(container, parameters) {
    super(container, "Snackbar", parameters);
    this.snackbarTimer = 0;
    this.displayDelay = 6000;
    this.launchTime = null;
  }

  pausedAnimation() {
    const stopTime = new Date().getTime();
    clearTimeout(this.snackbarTimer);
    const passedTime = stopTime - this.launchTime;
    if (passedTime <= this.displayDelay) {
      this.displayDelay = this.displayDelay - passedTime;
    }
  }

  resetTimeout() {
    this.displayDelay = 6000;
  }

  startAnimation() {
    this.launchTime = new Date().getTime();
    this.container.classList.add("show");
    this.snackbarTimer = setTimeout(() => {
      this.container.className = this.container.className.replace("show", "");
      this.resetTimeout();
    }, this.displayDelay + 150);
  }

  init(container, parameters) {
    super.init(container, parameters);
    this.boundPausedAnimation = this.pausedAnimation.bind(this);
    this.boundStartAnimation = this.startAnimation.bind(this);
    this.container.addEventListener("mouseenter", this.boundPausedAnimation);
    this.container.addEventListener("focus", this.boundPausedAnimation);
    this.container.addEventListener("mouseleave", this.boundStartAnimation);
    this.container.addEventListener("blur", this.boundStartAnimation);
  }

  dispose() {
    this.container.removeEventListener("mouseenter", this.boundPausedAnimation);
    this.container.removeEventListener("focus", this.boundPausedAnimation);
    this.container.removeEventListener("mouseleave", this.boundStartAnimation);
    this.container.removeEventListener("blur", this.boundStartAnimation);
    this.resetTimeout();
    clearTimeout(this.snackbarTimer);

    super.dispose();
  }
}

window.Snackbar = Snackbar;

export default Snackbar;
