class Accordion {
  toogleBodyDisplay(elementIndex) {
    let element = this.accordionsCardsLinks[ elementIndex ];
    let isExpanded = element.getAttribute("aria-expanded");
    let dataTargetAttr = element.getAttribute("data-target");
    if (isExpanded === "true") {
      element.setAttribute("aria-expanded", "false");
      if (!this.findHashTag(dataTargetAttr)) {
          document.getElementById(dataTargetAttr).classList.remove("show");
      };
      element.classList.remove("show");
    } else {

      // si l'accordéon n'est pas en mode multi, fermer toutes les lignes
      if (!element.parentElement.parentElement.classList.contains("multi")) {
        for (let i = 0; i < this.accordionsCardsLinks.length; i++) {
          let elt = this.accordionsCardsLinks[ i ];
          elt.classList.remove("show");
        }
      }

      // ouvrir la ligne sélectionnée
      element.setAttribute("aria-expanded", "true");
      if (!this.findHashTag(dataTargetAttr)) {
         document.getElementById(element.getAttribute("data-target")).classList.add("show");
      };
      element.classList.add("show");
    }
  }

  // Verifier si un string a un hashtag préfixé au debut
  findHashTag(str) {
    return typeof str === "string" && str.indexOf("#") === 0;
  }

  init(container) {
    this.container = container;
    this.accordionsCardsLinks = container.querySelectorAll(".card-header a");

    this._onClick = (index) => {
      this.toogleBodyDisplay(index);
    };

    this._onClicks = [];
    for (let i = 0; i < this.accordionsCardsLinks.length; i++) {
      let linkToSet = this.accordionsCardsLinks[ i ];

      // On met un listener pour pouvoir ouvrir ou fermer un bloc de l'accordéon
      this._onClicks[ i ] = this._onClick.bind(this, i);
      linkToSet.addEventListener("click", this._onClicks[ i ]);
    }
  }

  constructor(container) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        Accordion.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return Accordion.refs[ container.dataset.ref ];
      }
    }
  }

  /**
   * Décharge le composant
   */
   dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.accordionsCardsLinks = container.querySelectorAll(".card-header a");
    for (let i = 0; i < this.accordionsCardsLinks.length; i++) {
      let linkToSet = this.accordionsCardsLinks[ i ];
      linkToSet.removeEventListener("click", this._onClicks[ i ]);
    }
  }
}

Accordion.refs = {};

document.addEventListener("DOMContentLoaded", () => {
  let accordions = document.querySelectorAll(".accordion");
  [].forEach.call(accordions, (accordion) => {
    new Accordion(accordion);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Accordion = Accordion;

export default Accordion;
