import Ob1Component from "./ob1-component";

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class SocialShareBar extends Ob1Component {

  constructor(container, parameters) {
    super(container, "SocialShareBar", parameters);
  }

  init(container, parameters) {
    super.init(container, parameters);

    container.querySelector(".ob1-social-share-copy-url").innerHTML = window.location.href;

    // Listener de l'Event "Click"
    container.querySelector(".ob1-social-share-copy-bar").addEventListener("click", this.copyToClipboard);

    container.querySelectorAll(".ob1-social-share-network").forEach(function (node) {

      // Si on a un attribut data-share sur nos nodes de partage
      if (node.dataset.share) {
        node.addEventListener("click", this.share);
        let href = node.getAttributeNode("href").value;

        // Et que le href de base n'est pas renseigné et/ou vide
        if (!href || href === "") {

          // On utilise le dataset, sinon, on utilise le href par défaut dans la fonction share()
          switch (node.dataset.share) {
            case "facebook":
              node.setAttribute("href", "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href));
              break;
            case "twitter":
              node.setAttribute("href", "https://twitter.com/intent/tweet?text=" + document.title + "&url=" + encodeURI(window.location.href + "&via="));
              break;
            case "linkedin":
              node.setAttribute("href", "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURI(window.location.href));
              break;
            case "pinterest":
              node.setAttribute("href", "https://pinterest.com/pin/create/button/?url=" + encodeURI(window.location.href));
              break;
            default:
              break;
          }
        }
      }
    }, this);
  }

  /**
   * Fonction de partage
   */
  share() {
    event.preventDefault();
    window.open(this.attributes.getNamedItem("href").nodeValue, "", "toolbar=0, status=0, width=600, height=600");
  }

  /**
   * Fonction de copie dans le presse-papier depuis le bouton de partage type "Copie de lien"
   */
  copyToClipboard() {
    navigator.clipboard.writeText(encodeURI(window.location.href))
      .then(() => {
      })
      .catch(err => {
        console.log("Could not copy to clipboard", err);
      });
  }

  dispose() {

    // Suppression des events listeners
    this.container.querySelector(".ob1-social-share-copy-bar").removeEventListener("click", this.copyToClipboard);
    super.dispose();
  }
}

window.SocialShareBar = SocialShareBar;

export default SocialShareBar;
