import Ob1Component from "./ob1-component";

class FormControlClear extends Ob1Component {

  constructor(container, parameters) {
    super(container, "FormControlClear", parameters);
  }

  /**
   * Initialisation du composant FormControlClear
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    this.clearIcon = this.container.parentElement.querySelector(".btn-clear");
    this._addEvents();
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents() {
    this._onInput = () => {
      if (!!this.container.value) {
        this.clearIcon.style.display = "inline-block";
      } else {
        this.clearIcon.style.display = "none";
      }
    };
    this.container.addEventListener("input", this._onInput);

    this._onClick = () => {
      this.container.value = "";
      this.clearIcon.style.display = "none";
      this.container.focus();
    };
    this.clearIcon.addEventListener("click", this._onClick);
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.container.removeEventListener("input", this._onInput);
    this.container.removeEventListener("click", this._onClick);

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }

}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.FormControlClear = FormControlClear;

export default FormControlClear;
