import Dropdown from "../../node_modules/boosted/js/dist/dropdown";

const classes = {
  active: "active"
};

const selectors = {
  dropdown_header: "h4.dropdown-header",
  link_item: ".dropdown-item:not(div)",
  div_item: "div.dropdown-item",
  control_input: ".custom-control-input"
};

const events = {
  click: "click"
};

class DropdownComponent extends Dropdown {

  constructor(element) {
    super(element);

    // On ajoute le nombre d'éléments sélectionnés dans le cas choix multiple et sous-rubrique
    if (this._element.querySelector(selectors.dropdown_header) && this._element.querySelector(selectors.div_item)) {
      this._setCounts();
    }
  }

  _addEventListeners () {

    // Sur les items lien du dropdown
    const linkItems = this._element.querySelectorAll(selectors.link_item);

    this._onLinkClick = (index, event) => {
      for (let childIndex = 0; childIndex < linkItems.length; childIndex++) {
        linkItems[ childIndex ].classList.remove(classes.active);
      }
      linkItems[ index ].classList.add(classes.active);

      event.preventDefault();
    };

    this._onLinkClicks = [];
    for (let index = 0; index < linkItems.length; index++) {
      this._onLinkClicks[ index ] = this._onLinkClick.bind(null, index);
      linkItems[ index ].addEventListener(events.click, this._onLinkClicks[ index ]);
    }

    // Sur les items checkbox du dropdown
    const divItems = this._element.querySelectorAll(selectors.div_item);

    this._onCheckboxClick = (event) => {

      // Si sous-rubriques, mise à jour de l'indicateur de filtres sélectionnés
      if (this._element.querySelector(selectors.dropdown_header) && event.target.nodeName === "INPUT") {
        let el = event.target.parentElement.parentElement;
        while (!el.matches(selectors.dropdown_header)) {
          el = el.previousElementSibling;
        }
        const count = this._countSelectedItemsUntilTitle(el);
        this._setCount(el, count);
      }

      // Empêche la fermeture du menu
      event.stopPropagation();
    };

    for (let index = 0; index < divItems.length; index++) {
      divItems[ index ].addEventListener(events.click, this._onCheckboxClick);
    }
  }

  /**
   * Ajoute le nombre d'éléments sélectionnés dans chaque section dans le cas d'un dropdown avec choix multiple et sous-rubrique
   */
  _setCounts () {
    const titles = this._element.querySelectorAll(selectors.dropdown_header);
    for (let index = 0; index < titles.length; index++) {
      const count = this._countSelectedItemsUntilTitle(titles[ index ]);
      this._setCount(titles[ index ], count);
    }
  }

  /**
   * Retourne le nombre d'item sélectionnés contenu dans la catégorie d'un item donné
   *
   * @param {HTMLElement} item
   * @return {number}
   */
  _countSelectedItemsUntilTitle (item) {

    // matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    var siblings = [];
    item = item.nextElementSibling;

    while (item) {
      if (item.matches(selectors.dropdown_header)) {
        break;
      }

      if (!item.querySelector(selectors.control_input).matches(":checked")) {
        item = item.nextElementSibling;
        continue;
      }

      siblings.push(item);
      item = item.nextElementSibling;
    }

    return siblings.length;
  }

  /**
   * Ajoute à un titre de sous-rubrique donné le nombre d'éléments sélectionnés donné entre parenthèses
   *
   * @param {HTMLElement} title
   * @param {number} count
   */
  _setCount (title, count) {
    if (/.*\([0-9]+\)$/.test(title.innerText)) {
      if (count === 0) {
        title.innerText = title.innerText.replace(/\([0-9]+\)$/, "");
      } else {
        title.innerText = title.innerText.replace(/\([0-9]+\)$/, "(" + count + ")");
      }
    } else {
      if (count === 0) {
        return;
      } else {
        title.innerText += " (" + count + ")";
      }
    }
  }

  /**
   * Décharge le composant
   */
   dispose () {

    // suppression de tous les event listeners qui ont été créés
    const linkItems = this._element.querySelectorAll(selectors.link_item);
    for (let index = 0; index < linkItems.length; index++) {
      linkItems[ index ].removeEventListener(events.click, this._onLinkClicks[ index ]);
    }

    const divItems = this._element.querySelectorAll(selectors.div_item);
    for (let index = 0; index < divItems.length; index++) {
      divItems[ index ].removeEventListener(events.click, this._onCheckboxClick);
    }

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }
}

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll(".dropdown")).forEach((dropdown) => {
    new DropdownComponent(dropdown);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.DropdownComponent = DropdownComponent;

export default DropdownComponent;
