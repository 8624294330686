import Ob1Component from "./ob1-component";

const selector = {
  item: ".breadcrumb-item"
};

class Breadcrumb extends Ob1Component {

  constructor(container, parameters) {
    super(container, "Breadcrumb", parameters);
  }

  /**
   * Initialisation du composant fil d'Ariane
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    this._addEvents();
    this.resize();
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents () {
    this._hOnResize = () => {
      clearTimeout(this.resizetimeout);
      this.resizetimeout = setTimeout(() => {
        this.resize();
      }, 50);
    };
    window.addEventListener("resize", this._hOnResize);
  }

  /**
   * Redimensionnement du fil d'Ariane en fonction de la place disponible
   */
  resize() {
    let breadcrumbItem = this.container.querySelectorAll(selector.item);

    // on réaffiche tous les éléments du fil d'Ariane
    [].forEach.call(breadcrumbItem, (item) => {
      item.classList.remove("sr-only");
    });

    // calcul de la largeur maximale du container
    let containerSize = this.container.offsetWidth;

    // on commence par le dernier élément car le fil d'Ariane affiche en priorité les éléments les plus profonds
    for (let i = breadcrumbItem.length - 1; i >= 0; i--) {
      let item = breadcrumbItem[ i ];
      containerSize -= item.offsetWidth;
      if (containerSize < 0) {

        // cet élément est trop grand, donc on le masque
        item.classList.add("sr-only");
      }
    }
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de l'event listener qui a été créé
    window.removeEventListener("resize", this._hOnResize);

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }
}

Breadcrumb.refs = {};

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Breadcrumb = Breadcrumb;

export default Breadcrumb;
