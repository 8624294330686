import * as OB1Common from "./ob1-common";

let refs = {};
let instanceCount = 0;

/**
 * Classe dont doivent hériter tous les composants OB1 pour normaliser le fonctionnement
 * En héritant de cette classe, on donne accès à de nouvelles propriétés :
 * - this.componentName : le nom du composant (le nom de la classe à partir de laquelle il est construit)
 * - this.ref : la référence du composant
 * - this.parameters : les paramètres d'instanciation (@see getDefaultParams(), _init() pour voir comment les paramètres sont construits)
 * - this.container : le noeud DOM à partir duquel le composant est construit (il y en a forcément un)
 *
 * On donne aussi accès à une fonction "bridge" rattachée au noeud DOM, qui permet de commander le composant.
 * Ex:
 * pour le composant FilterChipsBar, après l'instanciation du composant sur le noeud DOM node,
 * on a accès à node.FilterChipsBar pour accéder aux méthodes du composant :
 *
 * node.FilterChipsBar('METHOD_NAME', PARAMS)
 * comme : node.FilterChipsBar('onUpdateValues', function(details) {
        console.debug(details);
      });
 *
 */
export default class Ob1Component {

  /**
   * Construteur
   *
   * The constructor should only contain the boiler plate code for finding or creating the reference.
   *
   * @param {HTMLElement} container le container sur lequel est instancié le composant
   * @param {string} composantName le nom du composant
   * @param {object} parameters les paramètres d'instanciation
   * @return {*}
   */
  constructor(container, componentName, parameters) {
    this.componentName = componentName;
    if (container) {
      const refParamName = `ref${this.componentName}`;
      if (typeof container.dataset[ refParamName ] === "undefined") {
        this.ref = `${this.componentName}${++instanceCount}`;
        refs[ this.ref ] = this;
        container.dataset[ refParamName ] = this.ref;
        container[ this.componentName ] = this.execute.bind(this);
        this.init(container, parameters || {});
      } else {

        // If this element has already been instantiated, use the existing reference.
        return refs[ container.dataset[ refParamName ] ];
      }
    }
  }

  /**
   * Les paramètres par défaut du composant
   * Ils sont surchargés (par ordre de priorité) par :
   * - l'attribut data sur le noeud DOM du composant
   * - les paramètres utilisés au moment de l'instanciation
   * @returns {{}}
   */
  static getDefaultParams () {
    return {};
  }

  /**
   * Charge les composants ob1 présents dans un noeud DOM (ou dans ses fils)
   * @param {HTMLElement} node le noeud DOM dans lequel on essaie de trouver des composants ob1 à charger
   */
  static load (node) {
    node = node || document.body;
    let ob1ComponentNodes = node.querySelectorAll("[data-ob1-component]");

    [].forEach.call(ob1ComponentNodes, (ob1ComponentNode) => {
      const componentConstructorName = ob1ComponentNode.getAttribute("data-ob1-component");
      if (componentConstructorName) {
        const aComponentConstructorName = componentConstructorName.split(",").map((value) => value.trim());
        aComponentConstructorName.forEach((constructorName) => {
          if (constructorName && window[ constructorName ]) {
            new window[ constructorName ](ob1ComponentNode, {});
          }
        });
      }
    });
  }

  /**
   * Retourne l'éventuel composant défini sur le noeud DOM
   * @param {HTMLElement} node le noeud DOM dont on veut récupérer le composant
   * @return {Object} la liste des composants associés au noeud DOM,
   *    la clé de chaque élément étant le nom du constructeur d'un Ob1Component
   */
  static get (node) {
    let component = {};
    if (node) {
      const componentConstructorName = node.getAttribute("data-ob1-component");
      if (componentConstructorName) {
        const aComponentConstructorName = componentConstructorName.split(",").map((value) => value.trim());
        aComponentConstructorName.forEach((constructorName) => {
          if (!component[ constructorName ]) {
            const refParamName = `ref${constructorName}`;
            const ref = node.dataset[ refParamName ];
            if (ref) {
              component[ constructorName ] = refs[ ref ];
            }
          }
        });
      }
    }
    return component;
  }

  /**
   * Supprime les éléments génériques du composant
   */
  dispose () {
    delete this.container[ this.componentName ];
    delete this.container.dataset[ `ref${this.componentName}` ];
    delete refs[ this.ref ];
  }

  /**
   * Initialisation du composant, pour toute la partie générique d'un composant ob1
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres d'instanciation du composant
   */
  init(container, parameters) {

    /**
     * Le container principal du composant
     */
    this.container = container;

    // récupération des paramètres d'instanciation
    parameters = parameters || {};
    this.parameters = {};
    const defaultParams = this.constructor.getDefaultParams();
    for (const key in defaultParams) {
      if (defaultParams.hasOwnProperty(key)) {
        const type = typeof defaultParams[ key ];
        const dataSetParam = this.container.getAttribute(`data-${this.componentName.toLowerCase()}-${key}`);
        if (typeof dataSetParam !== "undefined") {
          this.parameters[ key ] = (type === "boolean" ?
            dataSetParam === "true" :
            dataSetParam);
        } else if (parameters.hasOwnProperty(key)) {
          this.parameters[ key ] = (type === "boolean" ?
            parameters[ key ] === "true" :
            parameters[ key ]);
        } else {
          this.parameters[ key ] = defaultParams[ key ];
        }
      }
    }
  }

  /**
   * Execute, pour cette instance, la méthode passée en paramètre si elle existe, avec tous les paramètres suivants
   * @param {array} params les paramètres d'éxécution :
   * - le premier paramètre indique l'action à exécuter
   * - les paramètres suivants sont utilisés comme paramètre d'appel à la méthode appelée
   */
  execute (...params) {
    if (params.length > 0) {
      const action = params.shift();
      if (this[ action ] && typeof this[ action ] == "function") {
        this[ action ](...params);
      } else {
        console.error(`[${this.componentName}] ${action} is not a function`);
      }
    } else {
      console.error(`[${this.componentName}] execute not enough parameters`);
    }
  }

  /**
   * Retourne true si on est en mobile, false sinon
   * Pour cela, on se base sur la largeur de l'écran et les points de rupture d'ob1
   * @return {boolean}
   */
  isMobile () {
    return window.innerWidth < OB1Common.breakpoints.MEDIUM;
  }
}

window.Ob1Component = Ob1Component;

// autoinstanciation des composants sur le DOMcontentLoaded
document.addEventListener("DOMContentLoaded", () => {
  Ob1Component.load(document.body);
});
