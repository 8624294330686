import Ob1Component from "./ob1-component";

const classes = {
  priceAmount: "ob1-price-amount",
  priceAmountInverse: "ob1-price-amount-inverse",
  pricePromoDurationByMonth: "ob1-price-promo-duration-by-month",
  priceAmountCustom: "ob1-price-amount-custom",
  pricePromoDurationByMonthCustom: "ob1-price-promo-duration-by-month-custom",
  priceWithoutPromo: "ob1-price-without-promo",

  // deprecated
  priceAmountDeprecated: "price-amount",
  priceAmountInverseDeprecated: "price-amount-inverse",
  pricePromoDurationByMonthDeprecated: "price-promo-duration-by-month",
  priceAmountCustomDeprecated: "price-amount-custom",
  pricePromoDurationByMonthCustomDeprecated: "price-promo-duration-by-month-custom"
};

const texts = {
  euros: "€",
  space: "\u0020",
  nonBreakingSpace: "\u00A0",
  narrowNonBreakingSpace: "\u202F"
};

const separators = {
  dot: ".",
  comma: ",",
  zeroCentsByUnit: "0€",
  zeroCentsByDecade: "00€"
};

const pricesToConvert = [ classes.priceAmount,
  classes.priceAmountInverse,
  classes.pricePromoDurationByMonth,
  classes.priceAmountCustom,
  classes.pricePromoDurationByMonthCustom,
  classes.priceWithoutPromo,

  // deprecated
  classes.priceAmountDeprecated,
  classes.priceAmountInverseDeprecated,
  classes.pricePromoDurationByMonthDeprecated,
  classes.priceAmountCustomDeprecated,
  classes.pricePromoDurationByMonthCustomDeprecated ];

class Price extends Ob1Component {

  constructor(container, parameters) {
    super(container, "Price", parameters);
  }

  /**
   * Initialisation du composant price
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    // Si le prix a un point comme séparateur entre la partie entière et les centimes, on remplace les points par des virgules
    this.replacePointByComma();

    // On supprime les centimes si ils sont à 0
    this.removeCentsIfRoundFigure();

    // On ajoute un espace fine insécable entre le montant et le symbole €
    this.addNarrowNonBreakingSpace();
  }

  /**
   * On ne doit avoir que des prix avec une virgule comme séparateur entre la partie entière et les centimes
   */
  replacePointByComma() {
    pricesToConvert.forEach((priceToConvert) => {
      let pricesAmounts = this.container.getElementsByClassName(priceToConvert);
      Array.from(pricesAmounts).forEach((priceAmounts) => {
        priceAmounts.innerText = priceAmounts.innerText.replace(separators.dot, separators.comma);
      });
    });
  }

  /**
   * On ne doit avoir que des prix avec une virgule comme séparateur entre la partie entière et les centimes
   */
  removeCentsIfRoundFigure() {
    pricesToConvert.forEach((priceToConvert) => {
      let pricesAmounts = this.container.getElementsByClassName(priceToConvert);
      Array.from(pricesAmounts).forEach((priceAmounts) => {
        let priceValues = priceAmounts.innerText.split(separators.comma);

        // On enlève les centimes si elles sont à 0 ou 00
        if (priceValues [ 1 ] && priceValues[ 1 ].substring(0, 2) === separators.zeroCentsByUnit) {
          let values = priceAmounts.innerText.split(separators.comma + separators.zeroCentsByUnit);
          priceAmounts.innerText = values[ 0 ] + texts.euros + values[ 1 ];
        }
        if (priceValues [ 1 ] && priceValues[ 1 ].substring(0, 3) === separators.zeroCentsByDecade) {
          let values = priceAmounts.innerText.split(separators.comma + separators.zeroCentsByDecade);
          priceAmounts.innerText = values[ 0 ] + texts.euros + values[ 1 ];
        }
      });
    });
  }

  /**
   * Ajout d'un espace fine insécable entre le montant et le symbole €
   * Remplace les espaces classiques et insécables par des espaces fine insécables
   */
  addNarrowNonBreakingSpace() {
    pricesToConvert.forEach((priceToConvert) => {
      let pricesAmounts = this.container.getElementsByClassName(priceToConvert);
      Array.from(pricesAmounts).forEach((priceAmounts) => {
        const amount = priceAmounts.innerText;
        const indexOfEuros = amount.indexOf(texts.euros);
        if (amount[ indexOfEuros - 1 ] === texts.narrowNonBreakingSpace) {
          return;
        } else if (amount[ indexOfEuros - 1 ] === texts.space) {
          priceAmounts.innerText = amount.replace(texts.space, texts.narrowNonBreakingSpace);
        } else if (amount[ indexOfEuros - 1 ] === texts.nonBreakingSpace) {
          priceAmounts.innerText = amount.replace(texts.nonBreakingSpace, texts.narrowNonBreakingSpace);
        } else {
          priceAmounts.innerText =
            [ amount.slice(0, indexOfEuros), texts.narrowNonBreakingSpace, amount.slice(indexOfEuros) ].join("");
        }
      });
    });
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Price = Price;

export default Price;
