import Ob1Component from "../ob1-component";

const CLASSES = {
  PROGRESS_BAR_DETERMINED: "ob1-progress-bar-determined"
};

const ATTRIBUTES = {
  ARIA_LABEL: "aria-label",
  ARIA_LABEL_PROGRESS_BAR: "Chargement en cours"
};

class ProgressBarDetermined extends Ob1Component {

  constructor(container, parameters) {
    super(container, "ProgressBarDetermined", parameters);
  }

  /**
   * Initialisation du composant
   * @param {HTMLElement} container - L'élément représentant la progress bar à temps déterminé
   * @param {object} parameters les paramètres d'instanciation de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    // On récupère la durée d'animation passé en data-attribute
    const duration = this.container.dataset.timer;

    // Si la barre de progression est utilisée dans un bouton, on doit créer la div contenant la barre de progression à l'intérieur du bouton
    const progressBar = this.initialisation();

    // On positionne le focus sur l'élément pour lancer la vocalisation
    this.container.setAttribute(ATTRIBUTES.ARIA_LABEL, ATTRIBUTES.ARIA_LABEL_PROGRESS_BAR);
    this.container.focus();

    // La durée de l'animation passée dans l'attibut data-time est appliquée
    progressBar.style.animationDuration = "500ms, " + duration + "s, 500ms";
    progressBar.style.animationDelay = "0s, 500ms, " + duration + ".5s";
    progressBar.firstChild.style.animationDuration = duration + "s";
  }

  /**
   * Initialisation de la barre de progression
   */
  initialisation() {
    let progressBar;

    // Si on doit afficher la barre de progression dans un bouton
    if (this.container.classList.contains("btn")) {
      const button = this.container;
      const progressBarDetermined = document.createElement("span");
      progressBarDetermined.classList.add(CLASSES.PROGRESS_BAR_DETERMINED);
      button.appendChild(progressBarDetermined);
      progressBar = progressBarDetermined;
    } else {
      progressBar = this.container;
    }

    // Ajout de l'élément inner
    const innerBar = document.createElement("span");
    innerBar.classList.add("ob1-progress-bar-determined-inner");
    progressBar.appendChild(innerBar);
    return progressBar;
  }

  /**
   * Reinitialisation de la barre de progression présente dans un bouton
   */
  progressBarInButtonReinitialisation() {
    this.container.removeAttribute("data-ob1-component");
    this.container.removeAttribute("data-timer");
    this.container.removeAttribute("data-ref-progress-bar-determined");
    this.container.removeAttribute(ATTRIBUTES.ARIA_LABEL);
    if (this.container.querySelector(".ob1-progress-bar-determined") !== null) {
      this.container.removeChild(this.container.querySelector(".ob1-progress-bar-determined"));
    }
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.ProgressBarDetermined = ProgressBarDetermined;

export default ProgressBarDetermined;
